import request from '@/utils/request'


// 查询优惠券类型列表
export function listCouponType(query) {
  return request({
    url: '/coupon/coupon/type/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券类型分页
export function pageCouponType(query) {
  return request({
    url: '/coupon/coupon/type/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券类型详细
export function getCouponType(data) {
  return request({
    url: '/coupon/coupon/type/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券类型
export function addCouponType(data) {
  return request({
    url: '/coupon/coupon/type/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券类型
export function updateCouponType(data) {
  return request({
    url: '/coupon/coupon/type/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券类型
export function delCouponType(data) {
  return request({
    url: '/coupon/coupon/type/delete',
    method: 'post',
    data: data
  })
}
